import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { deleteCookie, getCookie, setCookie } from 'app/__shared__/helpers/utils/cookie/cookie'
import { loginThunk, logoutThunk, meThunk, refreshTokenThunk } from './Auth.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../__shared__/components/store/thunkStatusHandler'

import { RootState } from '../__shared__/components/store/store'
import { jwtResponseInterface } from 'app/__shared__/model/interfaces/jwtResponseType'
import { notifyLoginSuccess } from 'app/__shared__/helpers/utils/notify/notifySuccess'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'
import toast from 'react-hot-toast'

export interface AuthInterface {
    jwt: jwtResponseInterface | null,
    data:{} | null,
    error: {} | null,
    message: string | null,
    status: statusEnum,
    userActiveSessionTime: number,
    isAuthenticated: boolean,
    user: any | null
}

const AuthState: AuthInterface = {
    jwt: null,
    data: null,
    error: null,
    message: null,
    status: statusEnum.loading,
    userActiveSessionTime: 0,
    isAuthenticated:false,
    user:null,
}


export const AuthSlice = createSlice({
  name: 'auth-slice',
  initialState: AuthState,
  reducers: {
    userSessionTimeIncrement: (state:AuthInterface, action: PayloadAction<number>) => {
        state.userActiveSessionTime += action.payload
    },
    toggleAuthStateFailed: (state:AuthInterface) => {
        state.status = statusEnum.failed
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(loginThunk.pending, (state:AuthInterface, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(loginThunk.rejected, (state:AuthInterface, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.jwt = null
        state.user = null
        state.error = action.payload
        state.isAuthenticated = false
        toast.error("Xəta baş verdi!")
    })
    
    builder.addCase(loginThunk.fulfilled, (state:AuthInterface, action:  PayloadAction<any>) => {
        state.jwt = action.payload.data
        setCookie('jwt', action.payload.data.access_token, 3500)
        toast.success("Hesabınıza daxil oldunuz")
        state.isAuthenticated = true
        state.error = null
        state.user = action.payload.data.user
        let permissions:any = {}
        action.payload.data.user.permissions.map((permission:any) => {
            permissions[permission.name] = permission.name
        })
        state.user.permissions = permissions
        setStatusSucceeded(state, action)
    })


    builder.addCase(meThunk.pending, (state:AuthInterface, action:  PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    builder.addCase(meThunk.rejected, (state:AuthInterface, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.jwt = null
        state.error = action.payload
        state.isAuthenticated = false
        state.error = null
        deleteCookie('jwt')
        console.log("jwt") 
    })
    
    builder.addCase(meThunk.fulfilled, (state:AuthInterface, action:  PayloadAction<any>) => {
        state.jwt = getCookie('jwt')
        state.user = action.payload.data
        let permissions:any = {}
        action.payload.data.permissions.map((permission:any) => {
            permissions[permission.name] = permission.name
        })
        state.user.permissions = permissions
        state.isAuthenticated = true
        state.error = null
        setStatusSucceeded(state, action)
    })


    builder.addCase(refreshTokenThunk.pending, (state:AuthInterface, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    builder.addCase(refreshTokenThunk.rejected, (state:AuthInterface, action: PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.jwt = null
        state.user = null
        state.error = action.payload
        state.isAuthenticated = false
        window.location.pathname = "/"
        deleteCookie('jwt')
    })
    builder.addCase(refreshTokenThunk.fulfilled, (state:AuthInterface, action: PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.jwt = action.payload.data
        setCookie('jwt', action.payload.data.access_token, 3500)
        state.isAuthenticated = true
        state.error = null
        state.user = action.payload.data.user
    })


    builder.addCase(logoutThunk.pending, (state:AuthInterface, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    builder.addCase(logoutThunk.rejected, (state:AuthInterface, action: PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.data = null
        state.user = null
        state.jwt = null 
        state.isAuthenticated = false
        deleteCookie('jwt')
        setTimeout(() => {
            window.location.pathname = "/"}
        , 1000)
    })
    builder.addCase(logoutThunk.fulfilled, (state:AuthInterface, action: PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.data = null
        state.user = null
        state.jwt = null 
        state.isAuthenticated = false
        deleteCookie('jwt')
        toast.success("Hesabınızdan çıxış etdiniz.")
        setTimeout(() => {
            window.location.pathname = "/"}
        , 1000)  
    })
    
  },
})


export const AuthActions = {
    ...AuthSlice.actions,
}


export const AuthData = (state:RootState) => state.AuthReducer

export const AuthReducer = AuthSlice.reducer
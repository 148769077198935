import "leaflet/dist/leaflet.css"
import 'leaflet-geosearch/dist/geosearch.css';

import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GeoSearchControl } from 'leaflet-geosearch';
import { Icon } from 'leaflet'
import L from "leaflet";
import {OpenStreetMapProvider} from 'leaflet-geosearch';
import { faLocation } from "@fortawesome/free-solid-svg-icons";
import logo from 'app/__shared__/assets/media/icon/location.png';

const myIcon = new Icon({
  iconUrl: logo,
  iconSize: [32,45]
})

 const SearchField = ({position,setPosition}:any) => {
    // const map = useMap();
    const provider = new OpenStreetMapProvider();

    const map = useMapEvents({
        click(e) {
            setPosition({...e.latlng})
        },
        locationfound(e) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
        },
        
    })

    // @ts-ignore
    const searchControl = new GeoSearchControl({
        provider: provider,
        marker: {
            // optional: L.Marker    - default L.Icon.Default
            icon: myIcon,
            draggable: true,
        },
        showMarker: false,
        style: 'bar',   
    })
  
    
    useEffect(() => {
        
        const yourEventHandler = (e:any) => {
            setPosition({
                ...e.marker.getLatLng()
            })
            // map.setZoom(15)
        }
        
        map.on('geosearch/showlocation', yourEventHandler);
        console.log(map)
        // map.on('dragend', yourEventHandler);
        
        // searchControl.on('markerfound', (e:any) => {
        //     console.log('markerfound', e)
        //     setPosition({
        //         ...e.latlng
        //     })
        // })
    }, [map])

    useEffect(() => {
        map.flyTo(position, 15)
    }, [position])
    
    

    useEffect(() => {
        map.addControl(searchControl);
        // return () => map.removeControl(searchControl);
    }, [])
    
    return null;
};

export const MapObjectSelectLocation = ({position, setPosition}:{position:{lat:number, lng:number}, setPosition:any}) => {
    const markerRef = useRef<any>(null)
    const [zoom, setZoom] = useState(15)

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker:any = markerRef.current
                if (marker != null) {
                    setPosition({...marker.getLatLng()})
                }
            },
        }),
        [],
    )
    

    const getMyLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log(position)
                setPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
            }   
        )
    }
    
    return (
        <div className="relative w-full">
            <button onClick={getMyLocation} type="button" className="btn p-3 bg-blue-500 z-50 rounded-full absolute bottom-0 left-0 m-3 shadow-lg text-white"><FontAwesomeIcon icon={faLocation}/> Olduğunuz məkanı klikləyərək paylaş</button>
            <MapContainer 
                className='h-96 z-10 w-full' 
                center={[position.lat, position.lng]} 
                zoom={zoom} 
                scrollWheelZoom={true}
            >
                <SearchField position={position} setPosition={setPosition}/>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <Marker
                    draggable={true}
                    eventHandlers={eventHandlers}
                    position={position}
                    icon={myIcon}
                    ref={markerRef}>
                    <Popup minWidth={90}>
                        <span>
                            {'Drag me!'}
                        </span>
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}










// function LeafletgeoSearch({map,setMap}:any) { 
//     const [cordinates, setCordinates] = useState<any>(null);
//     const [searchLocation, setSearchLocation] = useState<any>(null);
    

//     function searchEventHandler(result:any) { 
//         let resultLocation = result.location; 
//         setSearchLocation([resultLocation.x, resultLocation.y]); 
//         setCordinates({ 
//           coordinate_x: resultLocation.x, 
//           coordinate_y: resultLocation.y, 
//         }); 
//     }
      
//     useEffect(() => { 
//       const provider = new OpenStreetMapProvider(); 
//       const searchControl = GeoSearchControl({ 
//         provider, 
//         marker: { 
//           icon: myIcon, 
//         }, 
//       }); 
//       // setLastMarker(searchControl); 
//       // console.log(searchControl, "search"); 
//       // eslint-disable-next-line 
//       map.on("geosearch/showlocation", searchEventHandler); 
 
//       map.addControl(searchControl); 
//       return () => map.removeControl(searchControl); 
//       // eslint-disable-next-line 
//     }, []); 

//     return null; 
// }

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../../__shared__/components/store/thunkStatusHandler'

import { RootState } from '../../__shared__/components/store/store'
import { listAppealsThunk } from './Appeals.thunk'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface IAppealsSearch {
    status: statusEnum,
    data:any[],
}

export interface IAppeals {
    status:statusEnum,
    data:any[],
    searched_data?:IAppealsSearch
    toggle_search?:boolean
    pageParams:{ 
        pageLimit:number,
        currentPage:number,
        totalPages:number,
        totalRecords:number,
        name?:string,
        surname?:string,
        father_name?:string,
        min_weight?:string,
        max_weight?:string,
        min_height?:string,
        max_height?:string,
        is_military?:null|{id:number, name:string},
        has_crime?:null|{id:number, name:string},
        has_invalidity?:null|{id:number, name:string},
        gender?:null|{id:number, name:string},
        email?:string,
        address?:string,
        fin?:string,
        serial_number?:string,
        status?:null|{id:number,name:string},
        selectedBranches?:any[],
    }
} 

const AppealsState: IAppeals = {
    status: statusEnum.idle,
    toggle_search: false,
    data: [],
    pageParams:{ 
        pageLimit:8,
        currentPage:1,
        totalPages:1,
        totalRecords:0,
    }
}


export const ListAppealsSlice = createSlice({
  name: 'list-appeals-slice',
  initialState: AppealsState,
  reducers: {
    changePageLimit: (state:IAppeals, action: PayloadAction<number>) => {
        state.pageParams.pageLimit = action.payload
    },

    changeCurrentPage: (state:IAppeals, action: PayloadAction<number>) => {
        state.pageParams.currentPage = action.payload
    },

    resetAllParameters: (state:IAppeals) => {
        state.pageParams.pageLimit = 8
        state.pageParams.currentPage = 1
        state.pageParams.totalPages = 1
        state.pageParams.totalRecords = 0
    },

    toggleSearch: (state:IAppeals) => {
        state.toggle_search = !state.toggle_search
    },

    changeParams:(state:IAppeals, action: PayloadAction<any>) => {
        state.pageParams = action.payload
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(listAppealsThunk.pending, (state:IAppeals, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(listAppealsThunk.rejected, (state:IAppeals, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.data = []
        state.pageParams.currentPage = 1
        state.pageParams.totalPages = 1
        state.pageParams.totalRecords = 0
    })
    
    builder.addCase(listAppealsThunk.fulfilled, (state:IAppeals, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data.data
        state.pageParams.currentPage = action.payload.data.current_page
        state.pageParams.totalPages = action.payload.data.last_page
        state.pageParams.totalRecords = action.payload.data.total
    })

    
  },
})


export const ListAppealsActions = {
    ...ListAppealsSlice.actions,
}


export const ListAppealsData = (state:RootState) => state.AppealsReducer.ListAppealsReducer

export const ListAppealsReducer = ListAppealsSlice.reducer
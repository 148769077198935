export const translation:any = {
        ' name ':' Ad ',
        ' surname ':' Soyad ',        
        ' email ':' Elektron poçt ',
        ' father_name ':' Ata adı ' ,   
        ' serial_number ':" Seriya nömrəsi ",
        'father name':' Ata adı ' ,   
        'serial number':"Seriya nömrəsi ",
        ' fin ':" fin ",
        ' address ':' Ünvan ',
        ' phone ':' Telefon nömrəsi ',
        ' is_military ':' Hərbi mükəlləfiyyət ',
        ' has_crime ': " Cinayət məsuliyyətinə cəlb olunma ",
        ' has_invalidity ': " Əlillik ",
        " is military ":' Hərbi mükəlləfiyyət ',
        ' has crime ': " Cinayət məsuliyyətinə cəlb olunma ",
        ' has invalidity ': " Əlillik ",
        ' gender ': " Cinsiyyət ",
        ' weight ': " Çəki ",
        ' height ': " Boy hündürlüyü ",
        ' birthday ': " Doğum tarixi ",
        ' image ': " Şəkil ",
        ' video ': " Video ",
}